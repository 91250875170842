import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { ColorDot, DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { MessageListProjection, MessageListProjectionStateEnum } from 'api/accounting/models';
import { Tooltip } from 'antd';
import { messagesListTranslations } from '../../translations';


interface Props {
  record: MessageListProjection,
}

function getDotColor(state: MessageListProjectionStateEnum): DotColorEnum {
  switch (state) {
    case MessageListProjectionStateEnum.READY:
      return DotColorEnum.BLUE;
    case MessageListProjectionStateEnum.SENDING:
      return DotColorEnum.YELLOW;
    case MessageListProjectionStateEnum.SENT:
      return DotColorEnum.GREEN;
    case MessageListProjectionStateEnum.FAILED:
      return DotColorEnum.RED;
    default:
      return DotColorEnum.BLACK;
  }
}

const StatusCell = ({ record }: Props) => {
  const { tl } = useContext(LanguageContext);
  const tooltipMessage = messagesListTranslations.states.tooltipMessages[record.stateTextDetails]
    ? tl(messagesListTranslations.states.tooltipMessages[record.stateTextDetails])
    : record.stateTextDetails;
  return (
    <div className="StatusCell">
      <ColorDot color={getDotColor(record.state)}>
        <Tooltip title={tooltipMessage} placement="bottomRight">
          <span className="state">{tl(messagesListTranslations.states[record.state])}</span>
        </Tooltip>
      </ColorDot>
    </div>
  );
};

export default StatusCell;
