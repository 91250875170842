import {
  useContext, useEffect, useState,
} from 'react';
import { ProfitAndLossReportViewContext } from 'pages/ProfitAndLossReport/ProfitAndLossReportView/serivces/ProfitAndLossReportViewContext';
import { useHistory, useLocation, useParams } from 'react-router';
import moment from 'moment';
import { formatDate } from 'lib/Utils';
import { useActivePropertyOwnerContracts } from 'services/PropertyOwnerContractsList/useActivePropertyOwnerContracts';
import _ from 'lodash';
import { AuthContext } from 'contexts/AuthContext';
import { GetMessagesUsingGETSourceTypeEnum, MessageControllerApi, MessageListProjectionDtoStateEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { useCurrentOverlayInfo } from 'components/OverlayRoute/services/useCurrentOverlayInfo';
import { Modal } from 'antd';
import Icon from '@ant-design/icons';
import { ICONS } from 'components/icons';
import {
  Configuration, DocumentLegacyControllerApi, DocumentProjectionDtoStateEnum, DocumentUpdateLegacyDto, DocumentUpdateLegacyDtoDocumentStateEnum,
} from 'api/document';
import { showNotification } from 'lib/Notification';
// eslint-disable-next-line import/no-cycle
import { PROFIT_AND_LOSS_REPORTS_PATH } from 'pages/ProfitAndLossReport/routes';
import { buildDocumentRecipients } from 'lib/messageUtils';
import { MessageSendingInitializationStateType } from 'pages/MessageSendingPage/interfaces';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';

export const useProfitAndLossReportHeader = () => {
  const [messagesExist, setMessagesExist] = useState(false);
  const [numberOfFailedMessages, setNumberOfFailedMessages] = useState(0);

  const { tl } = useContext(LanguageContext);
  const history = useHistory();
  const location = useLocation();
  const profitAndLossReportViewContext = useContext(ProfitAndLossReportViewContext);

  if (profitAndLossReportViewContext === undefined) {
    throw new Error('useProfitAndLossReportHeader must be used within a ProfitAndLossReportViewContextProvider');
  }

  const { profitAndLossReport, setProfitAndLossReport } = profitAndLossReportViewContext;

  const { activePropertyOwnerContracts } = useActivePropertyOwnerContracts(profitAndLossReport.data?.propertyId);

  const metaData = profitAndLossReport.data?.metaData ? JSON.parse(profitAndLossReport.data?.metaData) : {};

  const { documentDbId } = useParams<{ documentDbId: string }>();
  const { apiConfiguration, documentApiConfiguration } = useContext(AuthContext);
  const documentController = new DocumentLegacyControllerApi(documentApiConfiguration('document'));
  const messageControllerApi = new MessageControllerApi(apiConfiguration('accounting'));

  const { isOverlayOnTop } = useCurrentOverlayInfo();


  const { goBack } = useContext(OverlayContext);

  const onClose = () => {
    goBack();
  };

  useEffect(() => {
    const documentId = parseInt(documentDbId, 10);
    if (!Number.isNaN(documentId) && isOverlayOnTop) {
      messageControllerApi.getMessagesUsingGET({ sourceId: documentId, sourceType: GetMessagesUsingGETSourceTypeEnum.PROFIT_AND_LOSS })
        .then((resp) => {
          if (!_.isEmpty(resp.content)) {
            setMessagesExist(true);
            setNumberOfFailedMessages(resp.content?.filter(m => m.state === MessageListProjectionDtoStateEnum.FAILED)?.length);
          } else {
            setMessagesExist(false);
          }
        });
    }
  }, [documentDbId, isOverlayOnTop]);

  const onDelete = (documentId: number) => (
    documentController.deleteByFilterUsingDELETE({ documentIds: [documentId] })
      .then(() => {
        showNotification({
          type: 'success',
          message: tl(translations.notifications.deletionSuccess),
        });
        history.push(PROFIT_AND_LOSS_REPORTS_PATH);
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          type: 'error',
          message: tl(translations.notifications.deletionError),
        });
      })
  );

  const onCancelModal = (e) => {
    if (typeof e === 'function') {
      e();
    }
  };

  const onClickDelete = () => {
    Modal.confirm({
      onOk: () => onDelete(parseInt(documentDbId, 10)),
      onCancel: onCancelModal,

      icon: <Icon component={ICONS.statusError} />,
      title: tl(translations.profitAndLossReportView.deleteModal.title),
      content: tl(translations.profitAndLossReportView.deleteModal.content),
      okText: tl(translations.profitAndLossReportView.deleteModal.deleteButton),
      cancelText: tl(translations.profitAndLossReportView.deleteModal.cancel),
      okButtonProps: { className: 'Button', danger: true },
      cancelButtonProps: { className: 'Button' },
      width: 520,
      closable: true,
      className: 'ProfitAndLossReportDeleteActionWarningModal',
    });
  };

  const onEdit = () => {
    if (profitAndLossReport.data) {
      const { propertyId, name, id } = profitAndLossReport.data;
      let editUrl = `/profit-and-loss/${id}/edit?propertyId=${propertyId}&name=${encodeURIComponent(name)}`;
      if (metaData.startDate) editUrl = editUrl.concat(`&startDate=${encodeURIComponent(metaData.startDate)}`);
      if (metaData.endDate) editUrl = editUrl.concat(`&endDate=${encodeURIComponent(metaData.endDate)}`);
      history.push(editUrl);
    }
  };

  const onPrepareSendOut = () => {
    const documentRecipients = buildDocumentRecipients(profitAndLossReport.data?.id, activePropertyOwnerContracts);

    const messageSendingNavigationState: MessageSendingInitializationStateType = {
      sourceType: 'PROFIT_AND_LOSS',
      sourceId: profitAndLossReport.data?.id,
      properties: [{
        propertyId: profitAndLossReport.data?.propertyId,
        documents: [documentRecipients],
      }],
    };

    const sendUrl = `${location.pathname}/message-sending`;
    history.push(sendUrl, messageSendingNavigationState);
  };

  const onCheckMessages = () => {
    history.push(`/messages?sourceId=${documentDbId}`);
  };

  const intervalLabel = metaData?.startDate && metaData?.endDate ? `· ${formatDate(moment(metaData?.startDate))} - ${formatDate(moment(metaData?.endDate))}` : '';
  const subtitle = profitAndLossReport.data ? `${profitAndLossReport.data?.propertyIdInternal}  ·  ${profitAndLossReport.data?.propertyName} ${intervalLabel}` : '';

  const onClickPrimaryButton = () => {
    if (messagesExist) {
      onCheckMessages();
    } else {
      onPrepareSendOut();
    }
  };

  const primaryButtonLabel = messagesExist ? tl(translations.profitAndLossReportView.checkMessages) : tl(translations.profitAndLossReportView.prepareSend);

  const getState = () => {
    if (numberOfFailedMessages !== 0) return 'FAILED';
    if (messagesExist) return 'SENT';
    if (profitAndLossReport.data?.state === 'READY') return 'DRAFT';
    return profitAndLossReport.data?.state;
  };

  const state = getState();

  const onRetry = () => {
    const updateDto = { ...profitAndLossReport.data, documentState: DocumentUpdateLegacyDtoDocumentStateEnum.GENERATING } as unknown as DocumentUpdateLegacyDto;

    documentController.updateDocumentsUsingPUT({ updateDtos: [updateDto] })
      .then((response) => {
        setProfitAndLossReport(prev => prev.load({
          ...prev.data,
          state: response[0].state as unknown as DocumentProjectionDtoStateEnum,
        }));
      }).catch((err) => {
        console.error(err);
        showNotification({
          type: 'error',
          message: tl(translations.notifications.generationError),
        });
      });
  };

  const generationFailed = profitAndLossReport?.data?.state === DocumentProjectionDtoStateEnum.FAILED;

  return {
    title: profitAndLossReport.data?.name || '',
    subtitle,
    state,
    onClickDelete,
    onEdit,
    onClickPrimaryButton,
    onPrepareSendOut,
    primaryButtonLabel,
    messagesExist,
    onRetry,
    generationFailed,
    onClose,
  };
};
